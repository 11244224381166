import React, {useEffect, useReducer, useState} from 'react';
import {Grid, GridColumn, Loader, Segment} from "semantic-ui-react";
import axios from "axios";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import {toast} from "react-toastify";
import NotPassedCourses from "./NotPassedCourses";
import ExamsOnRegistration from "./ExamsOnRegistration";

const reducer = (state, action)=> {
    switch (action.type) {
        case 'courses':
            return {...state, courses: action.payload};
        case 'registeredCourses':
            return {...state, registeredCourses: action.payload};
        case 'selectedCourses':
            return {...state, selectedCourses: action.payload};
        case 'loader':
            return {...state, loader: action.payload};
        default:
            return state;

    }
};
const Exams = ({registrationId,registration, toggleExamForm}) => {
    const sspGrade = JSON.parse(localStorage.getItem('sspGrade'));
    const [state, dispatch] = useReducer(reducer,{
        courses: [],
        registeredCourses: [],
        selectedCourses: [],
        loader: false,
        editForm: false,

    });

    const [selectAllFlag, setSelectAllFlag] = useState(false);

    let toggleSelectAllFlag = () =>{
        setSelectAllFlag(!selectAllFlag)
    };

    useEffect(()=>{
        selectAllExams(selectAllFlag);
    },[selectAllFlag]);

    useEffect(()=>{
        getCourses()
        getRegisteredCourses()
    },[]);

    function getCourses(loader=true){
        if(loader){dispatch({type: 'loader', payload: true});}
        axios.get(`course-study-program/not-passed/by-student-study-program-and-grade/${sspGrade.studentStudyProgram.id}/${sspGrade.grade.id}`,).then(
            (res) => {
                let courses = res.data;
                dispatch({type: 'courses', payload: courses});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });

    }

    function getRegisteredCourses(loader=true){
        if(loader){dispatch({type: 'loader', payload: true});}

        axios.get(`student-study-program-exam-mark/by-exam-registration/${registrationId}`,).then(
            (res) => {
                let courses = res.data;
                dispatch({type: 'registeredCourses', payload: courses});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });

    }

  function addExams(){

        // dispatch({type: 'loader', payload: true});
        let obj= state.selectedCourses.map((el)=>(
            {
                examRegistration:{id:registrationId},
                courseStudyProgram: el,
                applicationDate: new Date(),
                committee: null,
                verbalExamMark: null,
                writtenExamMark: null,
                finalMark:  null,
                markDate: null,
                markEntryDate: null,
            }
            ));
        axios.post('student-study-program-exam-mark/save-list',[...obj]).then(
            (res) => {
                dispatch({type: 'selectedCourses', payload: []});
                toast('Uspješna prijava!')
                getRegisteredCourses(false);
                getCourses(false);
            }).catch((err) => {
            console.log('err', err);
            dispatch({type: 'loader', payload: false});
        });

    }

    function selectCourses(course){
        if ( state.selectedCourses.filter((e)=>(e === course)).length === 0){
            let selectedCourses = [...state.selectedCourses, course];
            dispatch({type: 'selectedCourses', payload: selectedCourses});
        } else{
            let selectedCourses = state.selectedCourses.filter((e)=>(e !== course));
            dispatch({type: 'selectedCourses', payload: selectedCourses});
        }
    }

    function selectAllExams(select) {
        if(select){
            let selectedCourses = [...state.courses.filter((e)=>(!state.registeredCourses.find((el)=>(el.courseStudyProgram.course.id === e.course.id))))];
            dispatch({type: 'selectedCourses', payload: selectedCourses});
        }else{
            dispatch({type: 'selectedCourses', payload: []});
        }
    }

    return (state.loader ?
            <div style={{ paddingTop: '100px', paddingBottom: '100px' }}><Loader active inline="centered" /></div>
            :
            <Segment>
                <Button floated={"right"} icon size={"mini"} basic onClick={toggleExamForm}>
                    <Icon name='close'/>
                </Button>
                <Grid columns={2}>
                    <GridColumn>
                        <NotPassedCourses
                            courses={state.courses}
                            registeredCourses={state.registeredCourses}
                            applicationDate={registration.applicationDate}
                            selectAllFlag={selectAllFlag}
                            selectCourse={selectCourses}
                            selectedCourses={state.selectedCourses}
                            toggleSelectAllFlag={toggleSelectAllFlag}
                            addExams={addExams}
                        />
                    </GridColumn>
                    <GridColumn>
                        <ExamsOnRegistration
                            courses={state.registeredCourses}
                            applicationDate={registration.applicationDate}
                            // selectAllFlag={selectAllFlag}
                            // selectCourse={selectCourses}
                            // selectedCourses={state.selectedCourses}
                            // toggleSelectAllFlag={toggleSelectAllFlag}
                        />
                    </GridColumn>
                </Grid>
            </Segment>
    );
};

export default Exams;
